import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import "firebase/analytics";
import "firebase/storage";
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import functions from './functions';
import Notifications from '@/notifications';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false
Vue.use(vuetify);

const { firebaseConfig } = require('../public/js/firebase-config.js');
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();

// エミュレータの利用の有無
const useEmulator = false;
if (useEmulator && location.hostname === "localhost") {
  auth.useEmulator("http://localhost:9099");
  db.useEmulator("localhost", 12351);
}

Vue.prototype.$firebase = firebase;
Vue.prototype.$auth = auth;
Vue.prototype.$db = db;
Vue.prototype.$storage = storage;
Vue.prototype.$functions = functions.init(firebase);

// 認証状態の永続性の変更
auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);  // 画面を閉じたらログアウトされる

// ログインチェック
let lastClicked = new Date().getTime();
const checkExpiration = () => {
  if (auth.currentUser && new Date().getTime() - lastClicked > 1000 * 60 * 180) {
    // console.log('しばらく操作がなかったため、ログアウトしました。');
    auth.signOut();
    lastClicked = new Date().getTime();
    router.push('/login');
  }
};

addEventListener('click', ()=>{
  // 最後にクリックした時間を保存
  lastClicked = new Date().getTime();
  // console.log(lastClicked);
});
addEventListener('focus', ()=>{
  checkExpiration();
})
setInterval(() => {
  checkExpiration();
}, 1000);

const getTermsVersion = (fileName) => {
  const values = fileName.split('-');
  let version = values[2].split('_');
  for (let i = 0; i < version.length; i++) {
    version[i] = parseInt(version[i]);
  }
  return version.join('.');
};

const getServiceType = (fileName) => {
  const values = fileName.split('-');
  return values[1];
};

const extractTermsOfServicePdfs = (pdfs, terms) => {
  let showPdfs = [];
  // 定義されているPDFの数だけループ
  for (const pdf of pdfs) {
    // サービス種別
    const serviceType = getServiceType(pdf.name);
    if (serviceType in terms) {
      // サービス種別に対する同意履歴がある場合
      const pdfVersions = getTermsVersion(pdf.name).split('.');     // PDFのバージョン
      const termVersions = terms[serviceType].version.split('.');   // 同意済みのバージョン
      // バージョンのレベル数だけループ（３つで固定）
      for (let i = 0; i < 3; i++) {
        const pdfVersion = pdfVersions.length >= i + 1 ? parseInt(pdfVersions[i]) : 0;
        const termVersion = termVersions.length >= i + 1 ? parseInt(termVersions[i]) : 0;
        if (pdfVersion > termVersion) {
          // PDFのバージョンより、記録済みのバージョンが小さい場合、NG
          showPdfs.push(pdf);
          break;
        }
      }
    } else {
      // 同意記録のないPDFがある場合、NG
      showPdfs.push(pdf);
    }
  }
  return showPdfs;
};

router.beforeEach((to, from, next) => {
  //console.log(from.path, to.path, store.state.user);

  if (to.path === '/login') {
    // ログイン画面の場合、そのまま遷移
    next();
  } else if (store.state.user != null) {
    // ログイン画面以外、かつ認証済みの場合
    if (to.path === '/terms-of-service') {
      // 利用規約画面の場合
      if (!('pdfs' in store.state.showTerms) || !store.state.showTerms.pdfs || store.state.showTerms.pdfs.length == 0) {
        // 表示する利用規約が設定されていない場合は、一旦ホームへ遷移し、改めて判定させる
        next('/');
        return;
      }
      // 表示する利用規約がある場合は、そのまま遷移
      next();
    } else if (to.path === '/terms-of-service-for-analysis') {
      if (!('pdfs' in store.state.showTermsForAnalysis) || !store.state.showTermsForAnalysis.pdfs || store.state.showTermsForAnalysis.pdfs.length == 0) {
        // 表示する利用規約が設定されていない場合
        if (
          store.state.user.role == 'customer-admin'
          && (
            !('is_contracted_analysis_option' in store.state.userOrg)
            || store.state.userOrg.is_contracted_analysis_option === false
          )
        ) {
          // 医療機関管理スタッフ、かつオプション解析未契約の場合、全てのPDFを表示
          store.dispatch('setShowTermsForAnalysis', {pdfs: store.state.termsOfServiceForAnalysisPdfs, mode: 'new'});
        } else {
          next('/');
          return;
        }
      }
      // 表示する利用規約がある場合は、そのまま遷移
      next();
    } else {
      // 利用規約画面以外の場合

      // なりすましの場合は、なりすまし後ではなく、なりすまし前の情報を利用する
      const user = store.state.superUser != null ? store.state.superUser : store.state.user;

      //
      // 利用規約PDFが定義されていない場合、そのまま遷移
      //

      if (
        !('termsOfServicePdfs' in store.state)
        || !store.state.termsOfServicePdfs
        || store.state.termsOfServicePdfs.length == 0
      ) {
        next();
        return;
      }

      //
      // 利用規約同意の記録がない場合、利用規約同意画面へ
      //

      if (!('terms_of_services' in user) || !user.terms_of_services) {
        store.dispatch('setShowTerms', {pdfs: store.state.termsOfServicePdfs, mode: 'new'}); // 全てのPDF
        next('/terms-of-service');
        return;
      }

      //
      // 過去に同意記録がある場合の処理
      //

      const showPdfs = extractTermsOfServicePdfs(store.state.termsOfServicePdfs, user.terms_of_services);
      if (showPdfs.length > 0) {
        // 表示する必要のあるPDFがあった場合
        store.dispatch('setShowTerms', {pdfs: showPdfs, mode: 'update'});
        next('/terms-of-service');
        return;
      }

      //
      // オプション解析にたいする利用規約の処理
      //

      if (
        user.role == 'customer-admin'
        && 'is_contracted_analysis_option' in store.state.userOrg
        && store.state.userOrg.is_contracted_analysis_option === true
      ) {
        // 医療機関の管理スタッフ、かつオプション解析契約済みの場合
        if (
          'terms_of_services_for_analysis' in store.state.userOrg
          && store.state.userOrg.terms_of_services_for_analysis
        ) {
          // 同意記録有りの場合
          const showPdfs = extractTermsOfServicePdfs(store.state.termsOfServiceForAnalysisPdfs, store.state.userOrg.terms_of_services_for_analysis);
          if (showPdfs.length > 0) {
            // 表示する必要のあるPDFがあった場合
            store.dispatch('setShowTermsForAnalysis', {pdfs: showPdfs, mode: 'update'});
            next('/terms-of-service-for-analysis');
            return;
          }
        } else {
          // 同意記録無しの場合
          store.dispatch('setShowTermsForAnalysis', {pdfs: store.state.termsOfServiceForAnalysisPdfs, mode: 'update'});
          next('/terms-of-service-for-analysis');
          return;
        }
      }

      //
      // 基本はそのまま遷移
      //

      next();
    }
  } else {
    // 認証されていない場合
    // pendingしているパスを一時保存
//    window.localStorage.setItem('pendingPath', to.path);
    if (to.path === '/patient-register' && typeof to.query.serial !== 'undefined') {
      window.localStorage.setItem('pendingPath', to.path+'?serial='+to.query.serial+'&password='+to.query.password);
    } else {
      window.localStorage.setItem('pendingPath', to.path);
    }
    next('/login');
  }
});


// Vuexの初期化処理
store.commit('setDb', db);


// 通知の初期化
Notifications.initialize(firebase, store);
Vue.prototype.$notifications = Notifications;

new Vue({
  router,
  store,
  vuetify,
  // vuetify,
  render: h => h(App)
}).$mount('#app')
